/* PopupMessage.css */

/* PopupMessage.css */

.popup-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.popup-container {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 400px;
  animation: fade-in-up 0.5s ease-out forwards;
}

.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  transition: color 0.2s;
}

.popup-close-btn:hover {
  color: #e74c3c;
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.popup-message {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.popup-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.popup-input:focus {
  border-color: #3498db;
}

.popup-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
}

.popup-checkbox-container input {
  margin-right: 5px;
}

.popup-checkbox-container a {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.popup-submit-btn {
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-submit-btn:hover {
  background-color: #444;
}

.popup-remind-btn {
  background: none;
  border: none;
  color: #888;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.popup-remind-btn:hover {
  text-decoration: underline;
}

/* Animation for smooth appearance */
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .popup-container {
    width: 82%;
    padding: 25px;
  }
}
