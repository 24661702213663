/* Contact.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f7ff;
  padding: 20px;
}

.form-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  overflow: hidden;
  background-color: white;
}

.contact-info {
  flex: 1;
  padding: 20px;
  background-color: #f0f4f8;
  text-align: left;
  border-right: 1px solid #ccc;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.contact-details {
  list-style: none;
  padding: 0;
  font-size: 16px;
  color: #333;
}

.contact-hero {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.form-section {
  flex: 2;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-size: 24px;
  color: #0000ff;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input,
.textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.textarea {
  height: 100px;
}

.submit-button {
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
  width: 170px;
  align-self: center;
  border-radius: 10px;
  background-color: #524be3;
  color: white;
  cursor: pointer;
  border: none;
}

/* Media Queries for mobile */
@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
  }
  .contact-info {
    order: 1;
    text-align: center;
    padding: 20px;
  }
  .image {
    width: 80%;
    margin: 0 auto 20px;
  }
  .form-section {
    order: 2;
    padding: 20px;
  }
  .header {
    font-size: 22px;
    text-align: center;
  }
  .form {
    width: 100%;
  }
  .input,
  .textarea {
    width: 90%;
    font-size: 14px;
  }
  .submit-button {
    width: 50%;
  }
  .input,
  .textarea {
    margin-bottom: 2%;
  }
}
