.nav-link {
  display: inline-block;
  color: WHITE;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-link:hover {
  background-color: #ff6600;
  transform: scale(1.1);
  box-shadow: 0 0 10px #333;
}

@media (max-width: 768px) {
  .navNameMob {
    font-size: 30px !important;
  }
  .navLogo {
    margin-left: 0% !important;
  }
}
