/* src/App.css */
.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: #524be352;
}

body::-webkit-scrollbar-thumb {
  background-color: #ff9357;
  border-radius: 10px;
  border: 2px solid #f2f7ff;
}
.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.coming-soon {
  font-size: 8rem !important;
  color: #524be3;
  animation: fadeIn 5s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    color: #ff9357;
  }
  100% {
    opacity: 0;
  }
}
