.train-container {
  overflow: hidden;
  width: 80%;
  position: relative;
  margin-left: 10%;
  margin-top: 5%;
}

.train-content {
  display: flex;
  width: calc(200px * 12 + 20px * 12);
  animation: moveContent 20s linear infinite;
}

.train-card {
  width: 250px; /* Adjust width for rectangular shape */
  height: 120px; /* Adjust height for rectangular shape */
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */
  border-radius: 10px;
}

.image {
  width: 100px; /* Adjust size as needed */
  height: 100px;
}

@keyframes moveContent {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
.textClients {
  color: #524be3;
  font-weight: bolder;
  font-size: 26px;
}
.client-char {
  color: #1e1b1b;
  font-size: 22px;
  font-weight: normal;
}

@media (max-width: 768px) {
  .about-heading {
    font-weight: 500 !important;
    font-size: 24px !important;
  }
  .work-process {
    letter-spacing: 0em !important;
    color: #ff9357 !important;
    font-size: 24px !important;
  }
  .train-card {
    height: 100px !important;
    width: 150px !important;
  }
}
