body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.app {
  text-align: center;
}

.testimonials {
  background-color: #fff;
  padding: 50px 0;
}

.testimonials h2 {
  color: orange;
  font-size: 24px;
  margin-bottom: 10px;
}

.testimonials h3 {
  font-size: 32px;
  margin-bottom: 40px;
}

.testimonial-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  font-size: 14px;
  margin-bottom: 15px;
}

.testimonial-card h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.testimonial-card span {
  font-size: 12px;
  color: orange;
}

.footer {
  background-color: #003366;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-logo h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-links,
.footer-company {
  text-align: left;
}

.footer-links h3,
.footer-company h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-links ul,
.footer-company ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li,
.footer-company ul li {
  margin-bottom: 8px;
}

.footer-links ul li a,
.footer-company ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li p:hover,
.footer-company ul li a:hover {
  color: #ffa500;
}

.footer-contact form {
  display: flex;
  margin-bottom: 10px;
}

.footer-contact input {
  padding: 10px;
  flex: 1;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.footer-contact button {
  padding: 10px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.footer-contact button:hover {
  background-color: #ff7f00;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ffa500;
}

.footer-bottom {
  background-color: #002244;
  padding: 10px 0;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

@media (max-width: 768px) {
  .testimonials h3 {
    font-size: 24px;
  }
}
